import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { SubscriptionState } from './reducer';

export const getItem = (state: RootState) => state.subscription.item;
export const getErrors = (state: RootState) => state.subscription.errors;

export const getSubscription = (state: RootState) => state.subscription;

export const getIsSuccess = createSelector(
  getSubscription,
  (state: SubscriptionState) => state.isSuccess,
);

export const getIsFetching = createSelector(
  getSubscription,
  (state: SubscriptionState) => state.isFetching,
);
