import { useDispatch, useSelector } from 'react-redux';

import { getItem as getMemberDetail } from 'store/member/selectors';
import { doSubscribe } from 'store/subscription/actions';

export default function useSubscribe(location: number) {
  const dispatch = useDispatch();
  const member = useSelector(getMemberDetail);

  return async (emailId?: string) => {
    await dispatch(doSubscribe(emailId || member?.email, location, false));
  };
}
