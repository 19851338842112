import React from 'react';
import { useSelector } from 'react-redux';

import { cx } from '@travel/utils';

import PromotionBannerList from 'components/PromotionBannerList';

import { getIsFetching, getItems } from 'store/promotionBanners/selectors';

import { Translate } from 'core/translate';

import styles from './promotionBanners.module.scss';

function PromotionBanners(props: { onPage?: string }) {
  const items = useSelector(getItems);
  const isFetching = useSelector(getIsFetching);

  return (
    <PromotionBannerList
      isLoading={isFetching}
      className={cx(styles.wrapper, props.onPage === 'top' && styles.wrapperTop)}
      title={<Translate id="Top.Promotions_Deals.Title" />}
      banners={items}
    />
  );
}

export default PromotionBanners;
