import React from 'react';

import { deepLinkKeys } from '../../utils/seo';
import { RanData } from '../../store/ranStore/types';

type Props = {
  ranData?: RanData;
};

const RanDataFormInputs = (props: Props) => {
  const { ranData } = props;

  if (!ranData) {
    return null;
  }

  return (
    <>
      {ranData.ranDate ? (
        <input
          type="hidden"
          name={deepLinkKeys.ranDate}
          data-testid={`ranDataFormInputs-${deepLinkKeys.ranDate}-input`}
          value={ranData.ranDate}
        />
      ) : null}
      {ranData.ranSiteID ? (
        <input
          type="hidden"
          name={deepLinkKeys.ranSiteID}
          data-testid={`ranDataFormInputs-${deepLinkKeys.ranSiteID}-input`}
          value={ranData.ranSiteID}
        />
      ) : null}
      {ranData.scid ? (
        <input
          type="hidden"
          name={deepLinkKeys.scid}
          data-testid={`ranDataFormInputs-${deepLinkKeys.scid}-input`}
          value={ranData.scid}
        />
      ) : null}
    </>
  );
};

export default RanDataFormInputs;
