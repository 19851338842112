import { RefObject, useState, useRef, useCallback } from 'react';

const useScrollOnDragging = (scrollElementRef: RefObject<HTMLDivElement>) => {
  const [isDragged, setIsDragged] = useState(false);
  const startDragPositionX = useRef(0);
  const scrollerScrollLeft = useRef(0);

  const onMouseDown = useCallback(
    event => {
      if (!scrollElementRef.current) return;

      setIsDragged(true);
      startDragPositionX.current = event.pageX - scrollElementRef.current.offsetLeft;
      scrollerScrollLeft.current = scrollElementRef.current?.scrollLeft;
    },
    [scrollElementRef],
  );

  const onMouseOut = useCallback(() => {
    setIsDragged(false);
  }, []);

  const onMouseMove = useCallback(
    event => {
      if (!isDragged || !scrollElementRef.current) return;
      event.preventDefault();

      const diff = event.pageX - scrollElementRef.current.offsetLeft;
      const walk = (diff - startDragPositionX.current) * 0.1;
      scrollElementRef.current.scrollLeft = scrollElementRef.current.scrollLeft - walk;
    },
    [isDragged, scrollElementRef],
  );

  return { onMouseDown, onMouseOut, onMouseMove };
};

export default useScrollOnDragging;
