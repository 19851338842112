import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { CampaignOutline, LikeOutline, Member } from '@travel/icons/ui';
import { DESKTOP_SCREEN, useDeviceType, useTranslation } from '@travel/traveler-core/hooks';
import { FlatButton } from '@travel/ui';
import { cx } from '@travel/utils';

import { getRouter } from 'store/__router/selectors';
import { getIsFetching as getIsSubscribing } from 'store/subscription/selectors';

import { SUBSCRIPTION_LOCATION } from 'constants/subscriptionLocation';
import { Translate } from 'core/translate';
import useLogin from 'hooks/useLogin';

import SubscriptionInputForm from './SubscriptionInputForm';
import { SCREEN_CONTENT } from './subscriptionMode';
import useSubscribe from './useSubscribe';

import styles from './emailSubscription.module.scss';

type Props = {
  className?: string;
  formClassName?: string;
  location: number;
  screenType: SCREEN_CONTENT;
};

const EMAIL_SUBSCRIPTION_ID = 'email-subscription-id';

function EmailSubscription(props: Props) {
  const deviceType = useDeviceType();
  const isPC = deviceType === DESKTOP_SCREEN;
  const { location, screenType } = props;

  const isSubscribing = useSelector(getIsSubscribing);
  const onSubscribe = useSubscribe(location);
  const { onClickLogin: login } = useLogin();
  const router = useSelector(getRouter);
  const subscribeButtonLabel = useTranslation({ id: 'Top.Email_Subscription.Alt.Subscribe' });

  const {
    location: { location: routeLocation },
    baseUrl,
  } = router;

  const handleLogin = () => {
    let redirectUrl = baseUrl;

    if (location === SUBSCRIPTION_LOCATION.AREA_TOP) {
      redirectUrl += routeLocation.pathname + routeLocation.search;
    }

    login(redirectUrl);
  };
  const iconSize = isPC ? 32 : 30;

  const contentItemsObj: {
    [key: string]: {
      title: React.ReactNode;
      description: React.ReactNode;
      titleIcon: React.ReactNode;
      submitBtn?: React.ReactNode;
      subscribeBtn?: React.ReactNode;
    };
  } = useMemo(
    () => ({
      INPUT: {
        title: <Translate id="Top.Email_Subscription.Deal_Title" />,
        description: <Translate id="Top.Email_Subscription.New_Content" />,
        titleIcon: <CampaignOutline size={iconSize} />,
      },
      SUBSCRIBE: {
        title: <Translate id="Top.Email_Subscription.Deal_Title" />,
        description: <Translate id="Top.Email_Subscription.New_Content" />,
        titleIcon: <CampaignOutline size={iconSize} />,
        subscribeBtn: <Translate id="Email_Settings.Subscribe_Button" />,
      },
      EXISTING_MEMBER: {
        title: <Translate id="Email_Settings.Rakuten_Member_Email.Title" />,
        description: <Translate id="Email_Settings.Rakuten_Member_Email.Error_Message" />,
        titleIcon: <Member size={iconSize} />,
        submitBtn: <Translate id="Member_Login.Login.Login_Button" />,
      },
      SUCCESS: {
        title: <Translate id="Email_Settings.Subscribe_Result.Thank_You" />,
        description: <Translate id="Email_Settings.Subscribe_Result.Message_Member" />,
        titleIcon: <LikeOutline size={iconSize} />,
      },
      NON_MEMBER_SUCCESS: {
        title: <Translate id="Email_Settings.Subscribe_Result.Thank_You" />,
        description: <Translate id="Email_Settings.Subscribe_Result.New_Message" />,
        titleIcon: <LikeOutline size={iconSize} />,
        submitBtn: <Translate id="Member_Login.Login.Signup_Button" />,
      },
      ALREADY_SUBSCRIBE: {
        title: <Translate id="Email_Settings.Subscribe_Result.Thank_You" />,
        description: <Translate id="Email_Settings.Already_Subscribed.Message" />,
        titleIcon: <LikeOutline size={iconSize} />,
        submitBtn: <Translate id="Member_Login.Login.Signup_Button" />,
      },
    }),
    [iconSize],
  );

  const { titleIcon, title, description, submitBtn, subscribeBtn } = contentItemsObj[screenType];

  return (
    <div className={cx(styles.emailSubscriptionContent)} data-testid="emailSubscription-wrapper">
      <div className={styles.subscriptionContent}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleIcon}>{titleIcon}</div>
          <h2 className={styles.title}>{title}</h2>
        </div>
        <p id={EMAIL_SUBSCRIPTION_ID} className={styles.description}>
          {description}
        </p>
        {submitBtn && (
          <FlatButton
            className={styles.signInButton}
            onClick={handleLogin}
            data-testid="emailSubscription-subscription-button"
            // TODO: (A11Y) Add aria label
          >
            {submitBtn}
          </FlatButton>
        )}
        {subscribeBtn && (
          <FlatButton
            className={styles.subscribeButton}
            onClick={() => {
              onSubscribe();
            }}
            isLoading={isSubscribing}
            data-testid="emailSubscription-subscription-button"
            ariaLabel={subscribeButtonLabel}
          >
            {subscribeBtn}
          </FlatButton>
        )}
        {screenType === SCREEN_CONTENT.INPUT && (
          <SubscriptionInputForm ariaDescribedBy={EMAIL_SUBSCRIPTION_ID} location={location} />
        )}
      </div>
    </div>
  );
}

export default EmailSubscription;
