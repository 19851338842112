import React, { ReactNode } from 'react';

import { CampaignOutline, LikeOutline, Member } from '@travel/icons/ui';

import { Translate } from 'core/translate';

export enum SCREEN_CONTENT {
  INPUT = 'INPUT',
  SUBSCRIBE = 'SUBSCRIBE',
  ALREADY_SUBSCRIBE = 'ALREADY_SUBSCRIBE',
  SUCCESS = 'SUCCESS',
  NON_MEMBER_SUCCESS = 'NON_MEMBER_SUCCESS',
  EXISTING_MEMBER = 'EXISTING_MEMBER',
}

export type contents = {
  title: ReactNode;
  description: ReactNode;
  titleIcon: ReactNode;
  submitBtn?: ReactNode;
  subscribeBtn?: ReactNode;
};

export function getScreenConfig(isPC: boolean = true): { [type in SCREEN_CONTENT]: contents } {
  const iconSize = isPC ? 32 : 30;

  return {
    INPUT: {
      title: <Translate id="Top.Email_Subscription.Deal_Title" />,
      description: <Translate id="Top.Email_Subscription.New_Content" />,
      titleIcon: <CampaignOutline size={iconSize} />,
    },
    SUBSCRIBE: {
      title: <Translate id="Top.Email_Subscription.Deal_Title" />,
      description: <Translate id="Top.Email_Subscription.New_Content" />,
      titleIcon: <CampaignOutline size={iconSize} />,
      subscribeBtn: <Translate id="Email_Settings.Subscribe_Button" />,
    },
    EXISTING_MEMBER: {
      title: <Translate id="Email_Settings.Rakuten_Member_Email.Title" />,
      description: <Translate id="Email_Settings.Rakuten_Member_Email.Error_Message" />,
      titleIcon: <Member size={iconSize} />,
      submitBtn: <Translate id="Member_Login.Login.Login_Button" />,
    },
    SUCCESS: {
      title: <Translate id="Email_Settings.Subscribe_Result.Thank_You" />,
      description: <Translate id="Email_Settings.Subscribe_Result.Message_Member" />,
      titleIcon: <LikeOutline size={iconSize} />,
    },
    NON_MEMBER_SUCCESS: {
      title: <Translate id="Email_Settings.Subscribe_Result.Thank_You" />,
      description: <Translate id="Email_Settings.Subscribe_Result.New_Message" />,
      titleIcon: <LikeOutline size={iconSize} />,
      submitBtn: <Translate id="Member_Login.Login.Signup_Button" />,
    },
    ALREADY_SUBSCRIBE: {
      title: <Translate id="Email_Settings.Subscribe_Result.Thank_You" />,
      description: <Translate id="Email_Settings.Already_Subscribed.Message" />,
      titleIcon: <LikeOutline size={iconSize} />,
      submitBtn: <Translate id="Member_Login.Login.Signup_Button" />,
    },
  };
}
