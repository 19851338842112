import React, { ReactNode } from 'react';

import Media from '../Media';

import cx from '../../utils/classnames';
import ric from '../../utils/ric';

import styles from './articlePreview.module.scss';

type Props = {
  className?: string;

  /** icon to display */
  icon?: ReactNode;

  /** path for image component */
  imageUrl?: string;

  /** alt message for image */
  imageAlt?: string;

  /** url path for link */
  linkUrl?: string;

  /** title for link */
  linkTitle?: string | ReactNode;

  /** article media sizes */
  mediaSizes?: string;

  /** callback function when info icon is clicked */
  onClickInfoIcon?: () => void;

  /** subtitle to display */
  subtitle?: ReactNode;

  /** title to display */
  title: ReactNode;

  /** flag for display info icon */
  isShowInfoIcon?: boolean;

  /** flag for display bottom spacing between title and subtitle */
  isAddTitleBottomSpacing?: boolean;

  /** icon ID */
  iconId?: string;

  /** how to show image on SP */
  imageType?: 'thumbnail' | 'background';

  /** String to define loading attr on Image */
  loading?: 'eager' | 'lazy';
};

function ArticlePreview(props: Props) {
  const {
    className,
    icon,
    imageUrl,
    imageAlt,
    linkUrl,
    onClickInfoIcon,
    subtitle,
    title,
    isShowInfoIcon,
    isAddTitleBottomSpacing,
    iconId,
    imageType,
    loading,
    mediaSizes,
    ...rest
  } = props;

  const titleSpacingClassName = isAddTitleBottomSpacing ? styles.withBottomSpacing : '';
  const isBackground = imageType === 'background';

  // TODO QA#2: Line limitation for title and description
  // TODO QA#3: Position of icon info if the title is longer than 1 line
  // TODO QA#4: Page navigation with the whole component

  return (
    <article className={cx(className, styles.wrapper)} {...rest}>
      <a className={styles.link} href={linkUrl} data-testid="articlePreview-link-item">
        <div className={isBackground ? styles.backgroundWrapper : ''}>
          <Media
            className={!isBackground ? styles.image : styles.background}
            url={ric.url(imageUrl)}
            srcset={ric.srcset(imageUrl)}
            sizes={mediaSizes}
            alt={imageAlt || ''}
            loading={loading}
          />
          <div className={isBackground ? styles.floating : styles.contentBox}>
            <div className={cx(styles.titleBox, titleSpacingClassName)}>
              <h3 className={styles.title}>{title}</h3>
            </div>
            {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
          </div>
        </div>
      </a>
    </article>
  );
}

export default ArticlePreview;
