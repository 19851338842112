import React, { ChangeEvent, useState } from 'react';
import { useSelector } from 'react-redux';

import { SingleArrow } from '@travel/icons/ui';
import { useTranslation } from '@travel/traveler-core/hooks';
import useDeviceType, { MOBILE_SCREEN } from '@travel/traveler-core/hooks/useDeviceType';
import { Alert, FlatButton, TextField } from '@travel/ui';
import { cx } from '@travel/utils';

import { getIsFetching as getIsSubscribing } from 'store/subscription/selectors';

import { EMAIL_REGEXP } from 'constants/validations';
import { Translate } from 'core/translate';

import useSubscribe from '../useSubscribe';

//To DO Move Form CSS here
import styles from '../emailSubscription.module.scss';

type Props = {
  location: number;
  ariaDescribedBy?: string;
};

function SubscriptionInputForm(props: Props) {
  const isSubscribing = useSelector(getIsSubscribing);
  const onSubscribe = useSubscribe(props.location);
  const placeholder = 'anyone@rakuten.com';

  const [emailId, setEmailId] = useState('');
  const [hasError, setHasError] = useState(false);

  const deviceType = useDeviceType();
  const isSP = deviceType === MOBILE_SCREEN;
  const subscriptionInputLabel = useTranslation({ id: 'Top.Email_Subscription.Alt.Email_Input' });

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailId(event.target.value);

    if (hasError && validateEmail(event.target.value)) {
      setHasError(false);
    }
  };

  const validateEmail = (value: string) => {
    const isValid = EMAIL_REGEXP.test(String(value));
    return isValid;
  };

  const handleSubmit = () => {
    if (validateEmail(emailId)) {
      onSubscribe(emailId);
    } else {
      setHasError(true);
    }
  };

  return (
    <div data-testid="subscriptionInputForm-wrapper" className={styles.inputFormWrapper}>
      <>
        <TextField
          data-testid="emailSubscription-email-input"
          name="email"
          value={emailId}
          isFullWidth={isSP}
          className={cx(styles.emailInput)}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          trailingIcon={
            <FlatButton
              onClick={handleSubmit}
              className={styles.inputArrowBtn}
              data-testid="emailSubscription-subscription-button"
              isLoading={isSubscribing}
            >
              <SingleArrow color="white" size={12} />
            </FlatButton>
          }
          trailingIconClassName={styles.subscriptionArrowWrapper}
          inputProps={{
            type: 'email',
            required: true,
            placeholder: placeholder,
            'aria-describedby': props.ariaDescribedBy,
            'aria-label': subscriptionInputLabel,
          }}
          aria-describedby={props.ariaDescribedBy}
        />
        {hasError && (
          <>
            <Alert
              className={cx(styles.serverErrorAlert)}
              titleWrapperClassName={styles.inputAlertTitleWrapper}
              titleClassName={styles.inputAlertTitle}
              iconClassName={styles.alertIcon}
              type={'error'}
              title={
                <Translate
                  id="Traveler_Input_Error.Booking_Step_1.Email.Enter_Valid_Email"
                  className={styles.inputAlertTitle}
                />
              }
              isClosable={false}
            />
          </>
        )}
      </>
    </div>
  );
}

export default SubscriptionInputForm;
