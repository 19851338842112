import React from 'react';
import { useSelector } from 'react-redux';

import ScrollableSection from '@travel/traveler-core/components/ScrollableSection';
import useDeviceType, {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
} from '@travel/traveler-core/hooks/useDeviceType';
import { ArticlePreview } from '@travel/ui';
import { isEmptyArray } from '@travel/utils';

import ContentsCarousel from 'components/ContentsCarousel';
import WithSkeletonLoading from 'components/WithSkeletonLoading';

import { getIsFetching, getItems } from 'store/specialFeatureArticles/selectors';

import { SpecialFeatureArticlesItems } from 'SpecialFeatureArticles-Types';

import styles from './specialFeatureArticles.module.scss';

const PC_IMAGE_WIDTH = 286;

function ArticlePreviewItem(props: {
  item: SpecialFeatureArticlesItems;
  index: number;
  mediaSizes: string;
}) {
  const { item, index, ...rest } = props;

  return (
    <ArticlePreview
      data-testid="specialFeatureArticles-article-item"
      key={item.title}
      className={styles.article}
      imageUrl={item.img_src}
      linkUrl={item.href}
      title={item.title}
      isAddTitleBottomSpacing
      iconId={`${index}-icon`}
      loading="lazy"
      {...rest}
    />
  );
}

type Props = {
  /** Node for the section title */
  title: React.ReactNode;
  imageType?: 'background' | 'thumbnail';
};

const TRAVEL_GUIDE_HEADER_ID = 'travel-guide-header-id';

function SpecialFeatureArticles(props: Props) {
  const items = useSelector(getItems);
  const isFetching = useSelector(getIsFetching);
  const deviceType = useDeviceType();
  const isSP = deviceType === MOBILE_SCREEN;
  const isPC = deviceType === DESKTOP_SCREEN;

  if (isEmptyArray(items) && !isFetching) {
    return null;
  }

  const mediaSizes = isSP ? '50vw' : '20vw';

  const featureArticleContent = items?.map((item, index) => (
    <ArticlePreviewItem
      key={item?.title}
      item={item}
      index={index}
      mediaSizes={mediaSizes}
      {...{ imageType: props.imageType ?? isSP ? 'background' : 'thumbnail' }}
    />
  ));

  return (
    <section
      aria-labelledby={TRAVEL_GUIDE_HEADER_ID}
      className={styles.wrapper}
      data-testid="specialFeatureArticles-wrapper"
    >
      <h2 id={TRAVEL_GUIDE_HEADER_ID} className={styles.title}>
        {props.title}
      </h2>
      <div className={styles.articlesContainer}>
        <WithSkeletonLoading
          isLoading={isFetching}
          count={isSP ? 2 : 4}
          row={1}
          customStyle={isSP ? 'textWithImageRow' : 'textWithImageColumn'}
          direction="row"
          className={styles.loaderWrapper}
          skeletonImageClassName={styles.loaderImage}
        >
          <div className={styles.list}>
            {isPC ? (
              <ContentsCarousel
                fixedCardWidth={PC_IMAGE_WIDTH}
                className={styles.carousel}
                navButtonClassName={styles.carouselButton}
                displayCount={4}
              >
                {featureArticleContent}
              </ContentsCarousel>
            ) : (
              <ScrollableSection childClassName={styles.scrollableItem}>
                {featureArticleContent}
              </ScrollableSection>
            )}
          </div>
        </WithSkeletonLoading>
      </div>
    </section>
  );
}

export default SpecialFeatureArticles;
