import React, { ReactNode } from 'react';
import { useSelector } from 'react-redux';

import Media from '@travel/traveler-core/components/Media';
import ScrollableSection from '@travel/traveler-core/components/ScrollableSection';
import useDeviceType, {
  DESKTOP_SCREEN,
  MOBILE_SCREEN,
} from '@travel/traveler-core/hooks/useDeviceType';
import { Animation, FlatButton } from '@travel/ui';
import { cx, isEmptyArray } from '@travel/utils';

import ContentsCarousel from 'components/ContentsCarousel';
import ImageCard from 'components/ImageCard';
import Invisible from 'components/Invisible';
import WithSkeletonLoading from 'components/WithSkeletonLoading';

import { getLocation } from 'store/__router/selectors';
import { getAreas, getIsFetching, getRecommendedAreas } from 'store/areaDetails/selectors';

import { Media as MediaType } from 'AreaDetails-Types';
import { Translate } from 'core/translate';
import Link from 'core/universalRouter/Link';
import paths from 'core/universalRouter/paths';
import useLoadMoreWithAccordionHandler from 'hooks/useLoadMoreWithAccordionHandler';

import styles from './recommendations.module.scss';

type RecommendationTileProps = {
  name: string;
  id: string;
  media?: MediaType;
  mediaSizes?: string;
  searchString?: string;
  page: 'Top' | 'Area';
  deviceType: 'sp' | 'tl' | 'pc';
};

const PC_IMAGE_WIDTH = 192;

function RecommendationTile(props: RecommendationTileProps) {
  const { name, id, media, mediaSizes, searchString, page, deviceType } = props;
  const isMobile = deviceType === 'sp';

  const linkPath =
    page === 'Top'
      ? `${paths.area.pathResolver(id)}${searchString || ''}`
      : `${paths.providerList.pathResolver(id)}${searchString || ''}`;

  return isMobile ? (
    <ImageCard
      image={media}
      imageSizes={mediaSizes}
      pathTo={linkPath}
      className={cx(styles.itemSize, styles.tileContainer)}
      mediaCardClassName={styles.article}
      bottomHalfInfo={
        <h3 data-testid={`recommendations-recommendationItem-${name}`} className={styles.text}>
          {name}
        </h3>
      }
    />
  ) : (
    <Link to={linkPath} className={styles.cardContainer}>
      <div
        className={cx(styles.imageWrapper, styles.tileContainer)}
        data-testid="recommendations-recommendationItem-wrapper"
      >
        <Media
          className={styles.image}
          placeholderClassName={cx(styles.image, styles.itemSize, styles.roundedImage)}
          wrapperClassName={styles.imageWrapper}
          media={media}
          sizes={mediaSizes}
        />
        <h3
          data-testid={`recommendations-recommendationItem-${name}`}
          className={cx(styles.text, styles.underCardInfo)}
        >
          {name}
        </h3>
      </div>
    </Link>
  );
}

type RecommendationsProps = {
  title: ReactNode;
  page: 'Top' | 'Area';
};

const RECOMMENDATIONS_ID = 'recommendations-id';

export function Recommendations(props: RecommendationsProps) {
  const items = useSelector(getRecommendedAreas);
  const areaItems = useSelector(getAreas);
  const isFetching = useSelector(getIsFetching);

  const location = useSelector(getLocation);
  const deviceType = useDeviceType();
  const isPC = deviceType === DESKTOP_SCREEN;
  const isMobile = deviceType === MOBILE_SCREEN;

  const { title, page } = props;

  const recommendedAreas = page === 'Top' ? areaItems : items;

  const {
    hasMore,
    isExpanded,
    countAtFirstLoaded,
    maxDisplayedCount,
    isShowButton,
    onClick: onLoadMoreClick,
  } = useLoadMoreWithAccordionHandler({
    total: recommendedAreas?.length,
    limit: 3,
    countAtFirstLoaded: 3,
  });

  if (isEmptyArray(recommendedAreas) && !isFetching) {
    return null;
  }

  const mediaSizes = isMobile ? '100vw' : '25vw';
  const buttonLabel = (
    <Translate
      id={hasMore || !isExpanded ? 'Traveler_Common.Show_More' : 'Traveler_Common.Show_Less'}
    />
  );

  const slicedItems = (start?: number, end?: number) => {
    return recommendedAreas
      ?.slice(start, end)
      ?.map(item => (
        <RecommendationTile
          key={item.pathId}
          name={item.placeName}
          id={item.pathId}
          media={item.media?.[0]}
          mediaSizes={mediaSizes}
          searchString={location?.search}
          page={page}
          deviceType={deviceType}
        />
      ));
  };

  return (
    <section
      className={cx(styles.container, page === 'Area' && styles.containerArea)}
      data-testid="recommendations-wrapper"
      aria-labelledby={RECOMMENDATIONS_ID}
    >
      <h2 id={RECOMMENDATIONS_ID} className={styles.header}>
        {title}
      </h2>
      <div className={styles.recommendationsContainer} data-testid="recommendations-container">
        <WithSkeletonLoading
          isLoading={isFetching}
          row={3}
          length="long"
          count={isPC ? 6 : 3}
          customStyle="image"
          skeletonItemClassName={cx(styles.loader, styles.itemSize)}
          direction={isPC ? 'row' : 'column'}
        >
          {isMobile && slicedItems(0, countAtFirstLoaded)}
          {isMobile && maxDisplayedCount > countAtFirstLoaded && (
            <Animation
              wrapperClassName={styles.animationWrapper}
              isStartAction={isExpanded}
              type="fadeInDown"
              animationDuration="short"
            >
              {slicedItems(countAtFirstLoaded, maxDisplayedCount)}
            </Animation>
          )}
          {!isMobile &&
            (isPC ? (
              <ContentsCarousel
                fixedCardWidth={PC_IMAGE_WIDTH}
                className={styles.carousel}
                navButtonClassName={styles.carouselButton}
                displayCount={6}
              >
                {slicedItems()}
              </ContentsCarousel>
            ) : (
              <ScrollableSection childClassName={styles.scrollableSectionItem}>
                {slicedItems()}
              </ScrollableSection>
            ))}
          {/* for SEO */}
          {isMobile ? (
            <Invisible data-testid="recommendations-invisible">
              {slicedItems(maxDisplayedCount)}
            </Invisible>
          ) : null}
        </WithSkeletonLoading>
      </div>
      {isShowButton && isMobile ? (
        <div className={styles.loadMoreBox}>
          <FlatButton
            classType="secondary"
            className={styles.loadMoreButton}
            onClick={onLoadMoreClick}
            data-testid="recommendations-loadMore-button"
          >
            {buttonLabel}
          </FlatButton>
        </div>
      ) : null}
    </section>
  );
}

export default Recommendations;
