import { useEffect, useRef, useState } from 'react';

type Data = {
  total: number;
  limit: number;
  countAtFirstLoaded?: number;
  isExpanded?: boolean;
};

/**
 * To manually handle the load more state (in case of all data is ready) and expand/collapse state
 * The expected behavior is, the load more action will be continued until the maxDisplayedCount reaches the totalNumber
 * After that, the button action will handle the collapse/expand state
 *
 * Remark: If the loadmore data comes from API, this hook might not match your expectation
 */
const useLoadMoreWithAccordionHandler = (data: Data) => {
  const totalNumber = Number(data.total);
  const countAtFirstLoadedNumber = Number(data.countAtFirstLoaded || data.limit);

  const [maxDisplayedCount, setMaxDisplayedCount] = useState(countAtFirstLoadedNumber);
  const [isExpanded, setIsExpanded] = useState(data.isExpanded || true);
  const countAtFirstLoadedNumberRef = useRef(countAtFirstLoadedNumber);

  const hasMore = maxDisplayedCount < totalNumber;

  // to update the state when data has been changed
  useEffect(() => {
    if (countAtFirstLoadedNumberRef.current !== data.countAtFirstLoaded) {
      setMaxDisplayedCount(Number(data.countAtFirstLoaded || data.limit));
    }
  }, [data.countAtFirstLoaded, data.limit]);

  return {
    countAtFirstLoaded: countAtFirstLoadedNumber,
    hasMore,
    isExpanded,
    maxDisplayedCount,
    isShowButton: totalNumber > countAtFirstLoadedNumber,
    onClick: () => {
      if (maxDisplayedCount < totalNumber) {
        // if there is next items, then load more
        setMaxDisplayedCount(prevCount => (prevCount += data.limit));
      } else {
        // if there is no more items, then toggle an expand/collapse state
        setIsExpanded(prevState => !prevState);
      }
    },
  };
};

export default useLoadMoreWithAccordionHandler;
