import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { PromotionBannersState } from './reducer';

export const getPromotionBanners = (state: RootState) => state.promotionBanners;

export const getIsFetching = createSelector(
  getPromotionBanners,
  (state: PromotionBannersState) => state.isFetching,
);

export const getItems = createSelector(
  getPromotionBanners,
  (state: PromotionBannersState) => state.items,
);

export const getErrors = createSelector(
  getPromotionBanners,
  (state: PromotionBannersState) => state.errors,
);
