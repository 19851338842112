import { createSelector } from 'reselect';
import { RootState } from 'typesafe-actions';

import { SpecialFeatureArticlesState } from './reducer';

export const getSpecialFeatureArticles = (state: RootState) => state.specialFeatureArticles;

export const getIsFetching = createSelector(
  getSpecialFeatureArticles,
  (state: SpecialFeatureArticlesState) => state.isFetching,
);

export const getItems = createSelector(
  getSpecialFeatureArticles,
  (state: SpecialFeatureArticlesState) => state.items,
);

export const getErrors = createSelector(
  getSpecialFeatureArticles,
  (state: SpecialFeatureArticlesState) => state.errors,
);
