import env from '@travel/env';

export const getOmniClientId = (marketCode: string, isWidget?: boolean) => {
  const omniKoreaID = isWidget
    ? process.env.UNIVERSAL_OMNI_KOREA_ID
    : env('UNIVERSAL_OMNI_KOREA_ID');

  return marketCode.toLowerCase() === 'twn'
    ? 'travel_standard_taiwan_web'
    : marketCode.toLowerCase() === 'kor' && omniKoreaID
    ? omniKoreaID
    : 'travel_standard_global_web';
};

export const getOmniCountryCode = (language: string) => {
  return language?.split('-')?.[1] || 'JP';
};
