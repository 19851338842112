import { GetTranslation } from '@travel/translation';

// eslint-disable-next-line no-useless-escape
export const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const getEMAIL = (getTranslation: GetTranslation) => ({
  type: 'matches', // NOTE: don't use yup email regexp. use RFC5322 regexp
  params: [
    EMAIL_REGEXP,
    getTranslation({ id: 'Traveler_Input_Error.Edit_Basic_Info.Email.Enter_Valid_Email' }),
  ],
});

export const getEMAIL_REQUIRED = (getTranslation: GetTranslation) => ({
  type: 'required',
  params: [getTranslation({ id: 'Traveler_Input_Error.Edit_Basic_Info.Email.Enter_Valid_Email' })],
});
