import React from 'react';

import InvisibleCore from '@travel/traveler-core/components/Invisible';

type Props = {
  children?: React.ReactNode;
};

/**
 * Hide the children element for SEO purpose with display:none;
 */
function Invisible(props: Props) {
  return <InvisibleCore {...props} />;
}

export default Invisible;
