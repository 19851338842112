/**
 * These variable values are the same as scss variables
 * /travel-source/libraries/travel-ui-styles/src/abstracts/_variables.scss
 */
export const MOBILE_MIN_WIDTH = 320;
export const MOBILE_MAX_WIDTH = 743;
export const TABLET_MIN_WIDTH = 744;
export const TABLET_MAX_WIDTH = 1024;
export const DESKTOP_MIN_WIDTH = 1025;
export const DESKTOP_MAX_WIDTH = 1200;

export const travelerHeaderHeightPC = 70;
export const providerListSearchFormHeight = 84;
export const providerListSectionMarginTop = 40;
