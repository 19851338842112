import dayjs from 'dayjs';
import React, { useRef } from 'react';

import ScrollableSection from '@travel/traveler-core/components/ScrollableSection';
import useDeviceType, {
  MOBILE_SCREEN,
  TABLET_SCREEN,
} from '@travel/traveler-core/hooks/useDeviceType';
import { cx, isEmptyArray } from '@travel/utils';

import ContentsCarousel from 'components/ContentsCarousel';
import Media from 'components/Media';
import WithSkeletonLoading from 'components/WithSkeletonLoading';

import { autoFilledCheckInDate, autoFilledCheckOutDate } from 'constants/date';
import { baseUrl } from 'core/universalRouter/paths';
import { PromotionBannersItem } from 'PromotionBanners-Types';
import { Media as MediaType } from 'ProviderInformation-Types';

import styles from './promotionBannerList.module.scss';

type Props = {
  className?: string;
  /** String of section title */
  title: React.ReactNode;
  /** Array of promotion banners */
  banners?: Array<PromotionBannersItem>;
  /** Flag to define whether the data is being fetched or not */
  isLoading?: boolean;
};

const PC_IMAGE_WIDTH = 286;
const DATE_FORMAT = 'YYYYMMDD';
const PROMOTIONS_BANNER_HEADER_ID = 'promotions-banner-header';

function getPromotionHrefWithDate(href: string) {
  const [url, searchQuery] = href.split('?');
  const searchParam = new URLSearchParams(searchQuery);
  const startServiceDate = searchParam.get('startServiceDate');
  const endServiceDate = searchParam.get('endServiceDate');
  const today = dayjs()
    .hour(0)
    .minute(0)
    .second(0);

  if (!startServiceDate || !endServiceDate || dayjs(startServiceDate).isBefore(today)) {
    searchParam.set('startServiceDate', autoFilledCheckInDate.format(DATE_FORMAT));
    searchParam.set('endServiceDate', autoFilledCheckOutDate.format(DATE_FORMAT));
  }

  return `${url}?${searchParam.toString()}`;
}

function BannerPromotionItem(props: PromotionBannersItem & { mediaSizes?: string }) {
  const deviceType = useDeviceType();
  const isSP = deviceType === MOBILE_SCREEN;
  const clickedPosition = useRef<null | number>(null);
  const media = {
    url: props.img_src,
    alt: props.title,
  } as MediaType;

  const isProviderListPage = props.href?.includes(baseUrl.providerList);
  const hrefWithDate =
    props.href && isProviderListPage ? getPromotionHrefWithDate(props.href) : props?.href;
  const isDisableLazyload = !(isSP ? props.display_order > 2 : props.display_order > 4);

  return (
    <div className={styles.bannerItem} aria-label={media.alt ? '' : props.title}>
      <a
        className={styles.bannerLink}
        href={hrefWithDate || '/'}
        data-testid="bannerPromotionList-promotion-link"
        // Since this component is being used inside <ContentsCarousel /> that
        // having dragging event handler, the click event will be fired after onMouseUp event
        // To prevent click event fired on drag, we have to handle it here
        onMouseDown={(event: React.MouseEvent) => {
          clickedPosition.current = event.clientX;
        }}
        onClick={(event: React.MouseEvent) => {
          if (event.clientX !== clickedPosition.current) {
            event.preventDefault();
          }
        }}
      >
        <Media
          className={styles.image}
          media={media}
          sizes={props.mediaSizes}
          wrapperClassName={styles.mediaWrapper}
          isDisableLazyload={isDisableLazyload}
        />
      </a>
    </div>
  );
}

function BannerPromotionList(props: Props) {
  const deviceType = useDeviceType();
  const isTablet = deviceType === TABLET_SCREEN;
  const isMobile = deviceType === MOBILE_SCREEN;
  const isEmptyList = isEmptyArray(props.banners);

  if (isEmptyList && !props.isLoading) {
    return null;
  }

  const innerContent = props.banners?.map(banner => (
    <BannerPromotionItem
      key={banner.display_order}
      {...banner}
      mediaSizes={isMobile ? `100vw` : `30vw`}
    />
  ));
  return (
    <section
      className={cx(props.className, styles.wrapper)}
      data-testid="bannerPromotionList-wrapper"
      aria-labelledby={PROMOTIONS_BANNER_HEADER_ID}
    >
      <h2 id={PROMOTIONS_BANNER_HEADER_ID} className={styles.title}>
        {props.title}
      </h2>
      <WithSkeletonLoading
        isLoading={props.isLoading}
        count={3}
        customStyle="image"
        direction="row"
        skeletonImageClassName={styles.skeletonItem}
      >
        {!isEmptyList ? (
          !isTablet ? (
            <ContentsCarousel
              fixedCardWidth={PC_IMAGE_WIDTH}
              className={styles.carousel}
              contentsContainerClassName={styles.contentsContainer}
              displayCount={4}
            >
              {innerContent}
            </ContentsCarousel>
          ) : (
            <ScrollableSection childClassName={styles.scrollableSectionItem}>
              {innerContent}
            </ScrollableSection>
          )
        ) : null}
      </WithSkeletonLoading>
    </section>
  );
}

export default BannerPromotionList;
