import React, { ReactNode } from 'react';

import { cx, isNotEmptyValue } from '@travel/utils';

import Media, { MediaType } from 'components/Media';

import { Link } from 'core/universalRouter/Link';

import styles from './imageCard.module.scss';

type Props = {
  className?: string;
  mediaCardClassName?: string;
  bottomHalfInfo?: ReactNode;
  image?: MediaType;
  imageSizes?: string;
  pathTo: string;
  topLeftElement?: ReactNode;
  topRightElement?: ReactNode;
};

function ImageCard(props: Props) {
  const {
    className,
    mediaCardClassName,
    pathTo,
    image,
    imageSizes,
    bottomHalfInfo,
    topLeftElement,
    topRightElement,
  } = props;

  const articleContent = (
    <article className={cx(mediaCardClassName, styles.card)} data-testid="imageCard-wrapper">
      <div className={styles.info}>
        <div className={styles.topHalf}>
          {topLeftElement && <div className={styles.topLeft}>{topLeftElement}</div>}
          {topRightElement && <div className={styles.topRight}>{topRightElement}</div>}
        </div>
        <div className={styles.bottomHalf}>
          {bottomHalfInfo && <div className={styles.bottomHalfContent}>{bottomHalfInfo}</div>}
        </div>
      </div>
      <Media
        className={styles.image}
        placeholderClassName={cx(styles.image, styles.itemSize)}
        wrapperClassName={styles.imageWrapper}
        media={image}
        sizes={imageSizes}
      />
    </article>
  );
  return isNotEmptyValue(pathTo) ? (
    <Link to={pathTo} className={cx(className, styles.wrapper)}>
      {articleContent}
    </Link>
  ) : (
    <div className={cx(className, styles.wrapper)}>{articleContent}</div>
  );
}

export default ImageCard;
