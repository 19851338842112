import { cx } from '@travel/utils';
import React, { Children, ReactNode, useRef } from 'react';
import useScrollOnDragging from '../../hooks/useScrollOnDragging';

import styles from './scrollableSection.module.scss';

type ScrollableSectionProps = {
  children?: ReactNode;
  className?: string;
  childClassName?: string;
};
function ScrollableSection(props: ScrollableSectionProps) {
  const scrollerRef = useRef<HTMLDivElement | null>(null);
  const { onMouseDown, onMouseOut, onMouseMove } = useScrollOnDragging(scrollerRef);

  return (
    <div
      className={cx(styles.list, props.className)}
      ref={scrollerRef}
      onMouseDown={onMouseDown}
      onMouseMove={onMouseMove}
      onMouseLeave={onMouseOut}
      onMouseUp={onMouseOut}
      role="scrollbar"
      aria-controls="content"
      aria-orientation="horizontal"
      aria-valuemin={0}
      aria-valuenow={scrollerRef.current?.scrollLeft || 0}
      tabIndex={0}
    >
      {Children.map(Children.toArray(props.children), child => {
        return <div className={cx(styles.item, props.childClassName)}>{child}</div>;
      })}
    </div>
  );
}

export default ScrollableSection;
