import { useSelector } from 'react-redux';

import env from '@travel/env';
import { getLanguage, getMarket } from '@travel/i18n';
import { getOmniClientId } from '@travel/traveler-core/utils/omni';
import { stringify } from '@travel/utils';

import paths from 'core/universalRouter/paths';

function useLogin() {
  const baseUrl = env('UNIVERSAL_OMNI_LOGIN_URL');
  const language = useSelector(getLanguage);
  const { marketCode } = useSelector(getMarket);

  const onClickLogin = (callbackUrl?: string) => {
    // save omni callback url to localStorage
    if (callbackUrl) {
      localStorage.setItem('omniCallbackUrl', callbackUrl);
    }

    // composed Omni url
    const params = stringify({
      client_id: getOmniClientId(marketCode),
      redirect_uri: paths.auth.pathResolver(window.location.origin),
      scope: 'openid profile email',
      response_type: 'code',
      ui_locales: language,
      r10_audience: 'rid',
      max_age: '0',
    });

    const composedUrl = `${baseUrl}?${params}#/sign_in`;
    window.location.assign(composedUrl);
  };

  return { onClickLogin };
}

export default useLogin;
