import { useSelector } from 'react-redux';

import { isNotEmptyArray } from '@travel/utils';

import {
  getErrors,
  getIsSuccess,
  getItem as getMemberSubscription,
} from 'store/subscription/selectors';

import { SCREEN_CONTENT } from './subscriptionMode';

export const useSubscriptionScreenType = () => {
  const { isMember, isSubscribed } = useSelector(getMemberSubscription);
  const isSuccess = useSelector(getIsSuccess);
  const errors = useSelector(getErrors);

  if (isNotEmptyArray(errors) && errors[0]?.status === 406) {
    return SCREEN_CONTENT.EXISTING_MEMBER;
  }

  if (isNotEmptyArray(errors) && errors[0]?.status === 405) {
    return SCREEN_CONTENT.ALREADY_SUBSCRIBE;
  }

  if (isSuccess) {
    return !isMember && !isSubscribed ? SCREEN_CONTENT.NON_MEMBER_SUCCESS : SCREEN_CONTENT.SUCCESS;
  }

  if (!isMember && !isSubscribed) {
    return SCREEN_CONTENT.INPUT;
  }

  if (isMember && !isSubscribed) {
    return SCREEN_CONTENT.SUBSCRIBE;
  }

  if (isMember && isSubscribed) {
    return null;
  }

  return SCREEN_CONTENT.INPUT;
};

export default useSubscriptionScreenType;
